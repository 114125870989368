import React from 'react';
import { withSiteData } from '../components/SiteData'
import AppLayout from "../components/AppLayout";
import RouterLink from '../components/shared/RouterLink'
import PageInfo from '../components/PageInfo'
import FeatureContentPage from '../components/FeatureContentPage'
import Typography from '@material-ui/core/Typography'

export default withSiteData(({ productName }) => (
	<AppLayout>
		<PageInfo
			title={"Page Not Found - " + productName}
		/>
		<div className="tt-screen-content">
			<FeatureContentPage
				title="There was a problem locating that page."
				description={(
					<React.Fragment>
						<Typography paragraph>Either the page you are trying to view does not exist, or there was a problem loading it.</Typography>
						<Typography paragraph>If you followed a link here, it may be out of date.  <RouterLink to="/">Click here to go home.</RouterLink></Typography>
					</React.Fragment>
				)}
			/>
		</div>
	</AppLayout>
));
